import { useEffect } from "react";

export const useAriaHiddenTool = (ref: React.RefObject<HTMLElement>, isHidden: boolean): void => {
  useEffect(() => {
    if (ref.current) {
      const safeValue = isHidden ? "true" : "false";
      ref.current.setAttribute("aria-hidden", safeValue);
      ref.current.querySelectorAll("button, input, a")?.forEach((el) => {
        el.setAttribute("aria-hidden", safeValue);
      });
    }
  }, [isHidden, ref.current]);
};
