import React, { FC, useMemo } from "react";
import styled from "styled-components";
import CloseIcon from "../../assets/images/close_icon.svg";
import {
  vwMobile,
  vwDesktop,
  toREM,
} from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { useAppSelector } from "../../hooks";
import { useDispatch } from "react-redux";
import { closeLanguagePickerModal } from "../../store/modals";
import { animated, useSpring } from "react-spring";
import { useLanguagePickerStaticQuery } from "../../hooks/staticQueries/useLanguagePickerStaticQuery";
import { getTranslations } from "../../helpers/translations";
import { useIntl } from "../../hooks/useIntl";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { getAssetURL } from "../../helpers/assets";
import useRightToLeft from "../../hooks/useRightToLeft";

type LanguagePickerOption = {
  flag: string;
  label: string;
  code: string;
};

const LanguagePickerModal: FC = () => {
  const languagePickerData = useLanguagePickerStaticQuery();
  const intl = useIntl();
  const labels = useStaticLabels();
  const languagePicker = useAppSelector(
    (state) => state.modals.languagePicker,
  );
  const dispatch = useDispatch();

  const styles = useSpring({
    opacity: languagePicker?.show ? 1 : 0,
  });

  const handleCloseClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    dispatch(closeLanguagePickerModal());
  };

  const handleLanguageClick = (
    languagePickerOption: LanguagePickerOption,
  ) => {
    dispatch(closeLanguagePickerModal());
    window.location.href = `/${languagePickerOption.code}`;
  };

  const languagePickerOptions = useMemo(() => {
    const {
      directus: { language_selector },
    } = languagePickerData;
    const languagePickerLocales: Array<LanguagePickerOption> =
      language_selector
        ?.filter(
          (languagePicker: any) =>
            languagePicker?.locale?.enabled,
        )
        ?.map((languagePicker: any) => {
          const languagePickerTranslations =
            getTranslations(
              languagePicker,
              "translations",
              intl.locale,
            );
          return {
            code: languagePicker?.locale?.code,
            label:
              languagePickerTranslations?.language_label,
            flag: getAssetURL(languagePicker?.flag_image),
          };
        })
        .sort(
          (
            a: LanguagePickerOption,
            b: LanguagePickerOption,
          ) =>
            a?.label && b?.label
              ? a.label.localeCompare(b.label)
              : 0,
        );

    return languagePickerLocales;
  }, [languagePickerData, intl?.locale]);

  const isRLT = useRightToLeft();

  const isModalOpen = languagePicker?.show;

  return (
    <Modal
      style={styles}
      isRTL={isRLT}
      open={isModalOpen}
      role="dialog"
      aria-labelledby="language-picker-title"
      aria-hidden={!isModalOpen}
      aria-modal="true"
    >
      <CloseButton
        aria-label={"close"}
        onClick={handleCloseClick}
        aria-hidden={!isModalOpen}
      >
        <img src={CloseIcon} alt={"close"} />
      </CloseButton>
      <Title id={"language-picker-title"}>
        {labels?.languageSelectorTitle}
      </Title>
      <Languages>
        {languagePickerOptions?.map(
          (languagePickerOption) => (
            <Language
              key={languagePickerOption?.label}
              aria-label={languagePickerOption?.label}
              onClick={() =>
                handleLanguageClick(languagePickerOption)
              }
              aria-hidden={!isModalOpen}
            >
              <LanguageIcon
                src={languagePickerOption?.flag}
                alt={languagePickerOption?.label}
              />
              <LanguageLabel>
                {languagePickerOption?.label}
              </LanguageLabel>
            </Language>
          ),
        )}
      </Languages>
    </Modal>
  );
};

const CloseButton = styled.button`
  border: none;
  background: #ffffff;
  position: absolute;
  top: ${vwMobile(20)};
  right: ${vwMobile(20)};
  cursor: pointer;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: ${vwDesktop(80)};
    right: ${vwDesktop(100)};
  }
  & > img {
    width: ${vwMobile(28)};
    height: ${vwMobile(28)};
    object-fit: contain;
    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      width: ${vwDesktop(28)};
      height: ${vwDesktop(28)};
    }
  }
`;

const Modal = styled(animated.div)<{
  open: boolean;
  isRTL?: boolean;
}>`
  display: ${(props) => (props?.open ? "flex" : "none")};
  position: fixed;
  z-index: 9999;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${vwMobile(68)} ${vwMobile(19)};
  flex-direction: column;
  gap: ${vwMobile(40)};
  overflow-y: auto;
  direction: ${({ isRTL }) => (isRTL ? "rtl" : "ltr")};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(64)};
    padding: ${vwDesktop(94)} ${vwDesktop(100)};
  }
`;

const Title = styled.div`
  color: #000000;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  font-size: ${toREM(48)}rem;
  letter-spacing: 0.03em;
  line-height: ${toREM(56)}rem;
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    line-height: ${toREM(64)}rem;
  }
`;

const Languages = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${vwMobile(28)};
  grid-column-gap: ${vwMobile(28)};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: ${vwDesktop(28)};
    grid-column-gap: ${vwDesktop(28)};
  }
`;

const Language = styled.button`
  border: none;
  cursor: pointer;
  background: #ffffff;
  display: flex;
  align-items: center;
  gap: ${vwMobile(12)};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${vwDesktop(12)};
  }
`;

const LanguageIcon = styled.img`
  width: ${vwMobile(24)};
  height: auto;
  object-fit: contain;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(24)};
  }
`;

const LanguageLabel = styled.span`
  color: #000000;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 700;
  font-size: ${toREM(18)}rem;
  letter-spacing: 0.04em;
  line-height: ${toREM(24)}rem;
`;

export default LanguagePickerModal;
