import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  rem,
  toVWDesktop,
  vwDesktop,
  vwMobile,
} from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import MailingList from "../MailingList";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
  openSignUpModal,
  closeSignUpModal,
} from "../../store/modals";
import { useAriaHiddenTool } from "../../hooks/useAriaHiddenTool";

export default function SignUpModal(): JSX.Element | null {
  const signUpModal = useAppSelector(
    (state) => state.modals.signUp,
  );
  const dispatch = useAppDispatch();
  const backdropRef = useRef<HTMLDivElement>(null);
  const isModalHidden = !signUpModal?.show;

  useEffect(() => {
    const t = setTimeout(() => {
      if (signUpModal?.timesDisplayed === 0) {
        dispatch(openSignUpModal());
      }
    }, 3 * 1000);
    return () => clearTimeout(t);
  }, [signUpModal?.timesDisplayed]);

  useAriaHiddenTool(backdropRef, isModalHidden);

  return (
    <Backdrop
      role="dialog"
      ref={backdropRef}
      style={{ display: isModalHidden ? "none" : "flex" }}
      onClick={(e) =>
        e.target === e.currentTarget &&
        dispatch(closeSignUpModal())
      }
      aria-labelledby="mailingListModalTitle"
      aria-modal="true"
    >
      <Box role="document">
        <MailingList titleId="mailingListModalTitle" />
        <Close
          onClick={() => dispatch(closeSignUpModal())}
          aria-label="Close"
        >
          &times;
        </Close>
      </Box>
    </Backdrop>
  );
}

const Close = styled.button`
  position: absolute;
  top: ${vwMobile(10)};
  right: ${vwMobile(10)};
  width: ${vwMobile(30)};
  height: ${vwMobile(30)};

  font-size: ${rem(40)};
  line-height: 100%;

  background: transparent;
  border: none;
  cursor: pointer;

  ${MEDIA_DESKTOP} {
    top: ${vwDesktop(10)};
    right: ${vwDesktop(10)};
    width: ${vwDesktop(30)};
    height: ${vwDesktop(30)};
  }
`;

const Box = styled.div`
  width: 90%;
  position: relative;

  ${MEDIA_DESKTOP} {
    width: 50%;
    > section {
      padding: ${toVWDesktop(60)}vw;
    }
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  direction: ${(props) =>
    props.theme.isRTL ? "rtl" : "ltr"};

  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;
