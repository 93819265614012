import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { useMailingData } from "../../hooks/useMailingData";
import { ButtonType, Button } from "../shared/Button";
import Checkbox from "../shared/Checkbox/Checkbox";
import { KlaviyoService } from "../../services/klaviyo";
import Input from "../shared/Input";
import { pushGtmDataLayer } from "../../helpers/analytics";

export type MailingListProps = {
  mainTitle: string;
  description: string;
  inputPlaceholder: string;
  buttonLabel: string;
  termsAndConditions: string;
  klaviyoListId: string;
  successMessage: string;
  checkbox: boolean;
};

const MailingList = (props: { titleId?: string }): JSX.Element => {
  const data = useMailingData();
  const [email, setEmail] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<boolean>(true);

  const handleChange = useCallback(
    (e) => {
      e.preventDefault();
      setEmail(e.target.value);
    },
    [setEmail]
  );
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (email) {
        try {
          await KlaviyoService.subscribeMailingList(data?.klaviyoListId, {
            email,
          });
          setSubmitted(true);
          pushGtmDataLayer({
            event: "newsletter_sign_up",
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    [email, data?.klaviyoListId]
  );

  const handleAgreementChange = () => {
    setAgreement(!agreement);
  };

  return (
    <MailingContainer>
      <Title id={props.titleId}>{data.mainTitle}</Title>
      {submitted ? (
        <Description>{data.successMessage}</Description>
      ) : (
        <>
          <Description>{data.description}</Description>
          <Form>
            <Input
              name="email"
              label={data.inputPlaceholder}
              value={email}
              onChange={handleChange}
            />
            <MailingButton
              label={data.buttonLabel}
              onClick={handleSubmit}
              type={ButtonType.PRIMARY_DARK}
              aria-disabled={!email}
              disabled={data.checkbox ? !agreement : false}
            />
          </Form>
          {data?.termsAndConditions && data?.checkbox ? (
            <CheckboxContainer>
              <Checkbox
                name="agreement"
                checked={agreement}
                onChange={handleAgreementChange}
                label={data?.termsAndConditions}
              />
            </CheckboxContainer>
          ) : (
            <Terms
              dangerouslySetInnerHTML={{
                __html: data?.termsAndConditions,
              }}
            />
          )}
        </>
      )}
    </MailingContainer>
  );
};

const MailingContainer = styled.section`
  width: 100%;
  background-color: #dd9271;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${toVWMobile(40)}vw ${toVWMobile(24)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(80)}vw ${toVWDesktop(415)}vw;
  }
`;

const Title = styled.p`
  width: 100%;
  font-size: ${toREM(36)}rem;
  line-height: ${toREM(40)}rem;
  letter-spacing: 0.03em;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  color: #000000;
  text-align: center;
  text-transform: uppercase;
`;

const Description = styled.p`
  width: 100%;
  padding-top: ${toVWMobile(7)}vw;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(28)}rem;
  letter-spacing: 0;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 600;
  color: #000000;
  text-align: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(8)}vw;
    font-size: ${toREM(18)}rem;
    line-height: ${toREM(21)}rem;
    letter-spacing: 0.05em;
    font-weight: 500;
    font-family: ${(props) => props.theme.fontFamily},
      ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${toVWMobile(40)}vw;
  > div {
    flex: 1;
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    padding-top: ${toVWDesktop(40)}vw;
  }
`;

const MailingButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${toVWMobile(16)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: fit-content;
    min-width: auto;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: ${(props) => (props.theme.isRTL ? 0 : `${toVWDesktop(19)}vw`)};
    letter-spacing: 0.01em;
    padding-top: ${toVWDesktop(19)}vw;
    padding-bottom: ${toVWDesktop(18)}vw;
    margin-right: ${(props) =>
      props.theme.isRTL ? `${toVWDesktop(19)}vw` : 0};
  }
`;

const Terms = styled.p`
  width: 100%;
  padding-top: ${toVWMobile(31)}vw;
  font-size: ${toREM(14)}rem;
  line-height: ${toREM(18)}rem;
  letter-spacing: 0;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 600;
  color: #000000;
  text-align: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(20)}vw;
    font-size: ${toREM(14)}rem;
    line-height: ${toREM(18)}rem;
    letter-spacing: 0;
    font-weight: 600;
    font-family: ${(props) => props.theme.fontFamily},
      ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  }
`;

const CheckboxContainer = styled.div`
  width: 100%;
  padding-top: ${toVWMobile(31)}vw;
  line-height: ${toREM(18)}rem;
  letter-spacing: 0;
  color: #000000;
  text-align: center;

  div {
    gap: ${toVWMobile(5)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      gap: ${toVWDesktop(5)}vw;
    }
  }

  label {
    color: #000000;
    margin-top: ${toVWMobile(2)}vw;
    text-align: left;
    padding-left: ${toREM(8)}rem;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-top: ${toVWDesktop(2)}vw;
    }
  }

  label a {
    color: #0000ee;
  }

  input {
    color: #000000;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(20)}vw;
  }
`;

export default MailingList;
